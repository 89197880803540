body {
  word-break: keep-all;
  background-color: var(--cmt-color-background-default);
}

:root {
  --cmt-color-background: #f0f3f8;

  --cmt-color-background-navy: #11151c;
  --cmt-color-background-navy-secondary: #2e3749;
  --cmt-color-primary-red-light: #ff6565;
  --cmt-color-primary-red-dark: #ff8585;
  --cmt-color-white: #ffffff;
  --cmt-color-white-secondary: #ffffffb3;
  --cmt-color-white-tertiary: #ffffff4d;
  --cmt-color-black: #1c2126;
  --cmt-color-black-secondary: #505e6d;
  --cmt-color-black-tertiary: #2f333a;
  --cmt-color-green-30: #51DCDB;
  --cmt-color-navy-20: #E6EBFF;
  --cmt-color-navy-30: #C3C9E0;
  --cmt-color-navy-35: #AFB5CC;
  --cmt-color-navy-40: #9095A9;
  --cmt-color-navy-50: #7C8195;
  --cmt-color-navy-60: #686D81;
  --cmt-color-navy-70: #54596D;
  --cmt-color-navy-80: #3B4052;
  --cmt-color-navy-90: #222530;
  --cmt-color-background-default: var(--cmt-color-navy-90);
  --cmt-color-navy-opacity-95: rgba(17, 21, 28, 0.95);
  --cmt-color-red-50: #FF5D56;
  --cmt-color-text-primary: var(--cmt-color-white);
  --cmt-color-text-secondary: var(--cmt-color-navy-30);
  --cmt-color-text-tertiary: var(--cmt-color-navy-40);
  --cmt-color-text-disabled:var(--cmt-color-navy-50);
  --cmt-color-icon-tertiary: var(--cmt-color-navy-60);
  --cmt-color-icon-darkprimary: var(--cmt-color-navy-90);
  --cmt-color-surface-primary: var(--cmt-color-navy-80);
  --cmt-color-surface-secondary: var(--cmt-color-navy-35);
  --cmt-color-surface-navy-opacity-95: var(--cmt-color-navy-opacity-95);
  --cmt-color-primary-pink-default: var(--cmt-color-red-50);
  --cmt-color-border-primary: var(--cmt-color-navy-20);
  --cmt-color-border-tertiary: var(--cmt-color-navy-80);


  --cmt-typography-h1-font-size: 1.625rem;
  --cmt-typography-h1-line-height: 2rem;
  --cmt-typography-h1-font-weight: bold;

  --cmt-typography-h2-font-size: 1.375rem;
  --cmt-typography-h2-line-height: 2rem;
  --cmt-typography-h2-font-weight: bold;

  --cmt-typography-h3-font-size: 1.25rem;
  --cmt-typography-h3-line-height: 1.75rem;
  --cmt-typography-h3-font-weight: 600;

  --cmt-typography-body1-font-size: 1rem;
  --cmt-typography-body1-line-height: 1.5rem;
  --cmt-typography-body1-font-weight: 500;

  --cmt-typography-body2-font-size: 0.875rem;
  --cmt-typography-body2-line-height: 1.25rem;
  --cmt-typography-body2-font-weight: 500;

  --cmt-typography-body-strong-font-size: 1rem;
  --cmt-typography-body-strong-line-height: 1.25rem;
  --cmt-typography-body-strong-font-weight: 500;

  --cmt-typography-button-font-size: 1rem;
  --cmt-typography-button-line-height: 1.25rem;
  --cmt-typography-button-font-weight: bold;

  --cmt-typography-caption-font-size: 0.75rem;
  --cmt-typography-caption-line-height: 1rem;
  --cmt-typography-caption-font-weight: 500;


  /* 이 아래는 레거시(랜딩페이지)로 이후 삭제 */
  --cmt-legacy-typography-h1-font-size: 2.75rem;
  --cmt-legacy-typography-h2-font-size: 2.25rem;
  --cmt-legacy-typography-h3-font-size: 2rem;
  --cmt-legacy-typography-h4-font-size: 1.5rem;
  --cmt-legacy-typography-body1-font-size: 1.25rem;
  --cmt-legacy-typography-body2-font-size: 1rem;
  --cmt-legacy-typography-caption-font-size: 0.75rem;
  --cmt-legacy-typography-button-font-size: 1rem;

  --cmt-legacy-typography-h1-line-height: 3.375rem;
  --cmt-legacy-typography-h2-line-height: 2.75rem;
  --cmt-legacy-typography-h3-line-height: 2.25rem;
  --cmt-legacy-typography-h4-line-height: 1.75rem;
  --cmt-legacy-typography-body1-line-height: 2rem;
  --cmt-legacy-typography-body2-line-height: 1.5rem;
  --cmt-legacy-typography-caption-line-height: 1rem;
  --cmt-legacy-typography-button-line-height: 1.5rem;
}

@media (max-width: 800px) {
  :root {
    --cmt-legacy-typography-h1-font-size: 1.5rem;
    --cmt-legacy-typography-h2-font-size: 1.5rem;
    --cmt-legacy-typography-h3-font-size: 1.25rem;
    --cmt-legacy-typography-h4-font-size: 1.25rem;
    --cmt-legacy-typography-body1-font-size: 1rem;
    --cmt-legacy-typography-body2-font-size: 0.875rem;
    --cmt-legacy-typography-caption-font-size: 0.75rem;
    --cmt-legacy-typography-button-font-size: 0.875rem;

    --cmt-legacy-typography-h1-line-height: 2rem;
    --cmt-legacy-typography-h2-line-height: 2.125rem;
    --cmt-legacy-typography-h3-line-height: 2.125rem;
    --cmt-legacy-typography-h4-line-height: 1.5rem;
    --cmt-legacy-typography-body1-line-height: 1.5rem;
    --cmt-legacy-typography-body2-line-height: 1rem;
    --cmt-legacy-typography-caption-line-height: 1rem;
    --cmt-legacy-typography-button-line-height: 1rem;
  }
}
